/**
 * 组织类型
 * @export string
 * @param {*} e number
 */
export function invoiceType (e) {
  let str = ''
  switch (e) {
    case 0:
      str = '无需发票'
      break
    case 1:
      str = '普通发票'
      break
    case 2:
      str = '增值税专用发票'
      break
    default:
      str = '无需发票'
  }
  return str
}

/**
 * 证书业务单状态
 * @export string
 * @param {*} e
 */
export function certTransStatus (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '待上传资料'
      break
    case 2:
      str = '审核中'
      break
    case 3:
      str = '审核未通过'
      break
    case 4:
      str = '待付款'
      break
    case 5:
      str = '制证中'
      break
    case 6:
      str = '待续期'
      break
    case 7:
      str = '待解锁'
      break
    case 8:
      str = '制证中'
      break
    case 9:
      str = '待领取'
      break
    case 10:
      str = '已发货'
      break
    case 11:
      str = '已完成'
      break
    case 12:
      str = '已取消'
      break
    case 13:
      str = '已作废'
      break
    case 14:
      str = '待变更'
      break
    default:
      str = '--'
  }
  return str
}
/**
 * 渠道类型
 * @param e
 */
export function appType (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '官网'
      break
    case 2:
      str = '服务网点'
      break
    case 3:
      str = '接入平台'
      break
    case 4:
      str = '印章网'
      break
    case 5:
      str = '客户端'
      break
    default:
      str = '--'
  }
  return str
}
/**
 * 证件类型
 * @export string
 * @param {*} e
 */
export function IdentType (e) {
  let str = ''
  switch (e) {
    case '0':
      str = '居民身份证'
      break
    case '1':
      str = '护照'
      break
    case '2':
      str = '驾驶证'
      break
    case '3':
      str = '户口簿'
      break
    case '4':
      str = '社会保障卡'
      break
    case '5':
      str = '临时居民身份证'
      break
    case '6':
      str = '军人身份证件'
      break
    case '7':
      str = '武装警察身份证件'
      break
    case '8':
      str = '警察身份证件'
      break
    case '9':
      str = '海员证'
      break
    case 'A':
      str = '港澳居民来往内地通行证'
      break
    case 'B':
      str = '台湾居民来往大陆通行证'
      break
    case 'C':
      str = '外国人居留证'
      break
    case 'Z':
      str = '其他'
      break
    case 'O':
      str = '组织机构代码证'
      break
    case 'P':
      str = '普通营业执照'
      break
    case 'Q':
      str = '统一社会信用代码'
      break
    case 'R':
      str = '事业单位法人证书'
      break
    case 'S':
      str = '社会团体法人登记证书'
      break
    case 'T':
      str = '民办非企业单位登记证书'
      break
    case 'U':
      str = '工商登记证'
      break
    case 'V':
      str = '税务登记证'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 证书业务单类型
 * @export string
 * @param {*} e
 */
export function transactionType (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '证书新办'
      break
    case 2:
      str = '证书变更'
      break
    case 3:
      str = '证书续期'
      break
    case 4:
      str = '证书注销'
      break
    // case 5:
    //   str = '证书冻结'
    //   break
    // case 6:
    //   str = '证书解冻'
    //   break
    case 7:
      str = '证书解锁'
      break
    case 9:
      str = '证书补办'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 证书状态
 * @export string
 * @param {*} e
 */
export function certStatus (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '正常'
      break
    case -1:
      str = '已过期'
      break
    case -2:
      str = '注销(已续期)'
      break
    case -3:
      str = '注销(已变更)'
      break
    case -4:
      str = '已挂失'
      break
    case -5:
      str = '已注销'
      break
    // case -6:
    //   str = '注销(密钥更新)'
    //   break
    case -7:
      str = '注销(已补办)'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 组织类型
 * @export
 * @param {*} e
 */
export function orgType (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '企业'
      break
    case 2:
      str = '政府/事业单位'
      break
    case 3:
      str = '其他组织'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 证书类型
 * @export
 * @param {*} e
 * @returns
 */
export function CertType (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '个人证书'
      break
    case 2:
      str = '机构证书'
      break
    case 3:
      str = '机构个人证书'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 业务单附件类型
 *
 * @export
 * @param {*} e
 */
export function TransactionAttachType (e) {
  let str = ''
  switch (e) {
    case 'a':
      str = '申请表'
      break
    case 'b':
      str = '经办人身份证正面'
      break
    case 'c':
      str = '经办人身份证反面'
      break
    case 'd':
      str = '经办人手持身份证'
      break
    case 'e':
      str = '营业执照/组织机构代码证'
      break
    case 'f':
      str = '委托授权书'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 新闻类型
 *
 * @export
 * @param {*} e
 */
export function newsType (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '新闻资讯'
      break
    case 2:
      str = '行业资讯'
      break
    case 3:
      str = '最新动态'
      break
    case 4:
      str = '公司新闻'
      break
    case 5:
      str = '国内热点'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 新闻详情截取简介
 *
 * @export
 * @param {*} e
 */
export function interceptText (e) {
  let str = e.replace(/<[^>]+>/g, '')
  if (str.length > 117) {
    str = str.slice(0, 117)
    str += '...'
  }

  return str
}

// /**
//  * 集成中心状态简介
//  *
//  * @export
//  * @param {*} e
//  */
// export function integrationStatus (e) {
//   let str = ''
//   switch (e) {
//     case 1:
//       str = '待审核'
//       break
//     case 2:
//       str = '待付款'
//       break
//     case 3:
//       str = '已付款，信息确认中'
//       break
//     case 4:
//       str = '集成中'
//       break
//     case 5:
//       str = '已上线'
//       break
//     default:
//       str = '--'
//   }
//   return str
// }

/**
 * API接口集成分类
 *
 * @export
 * @param {*} e
 */
export function integrationType (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '身份证实名认证'
      break
    case 2:
      str = '运营商三要素验证'
      break
    case 3:
      str = '银行卡四要素验证'
      break
    case 4:
      str = '人脸识别'
      break
    case 5:
      str = '企业三要素校验'
      break
    case 6:
      str = '企业四要素校验'
      break
    case 7:
      str = '法定代表人认证'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 订单状态
 * @export
 * @param {*} e
 * @returns
 */
export function orderStatus (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '待审核'
      break
    case 2:
      str = '待付款'
      break
    case 3:
      str = '已付款，信息确认中'
      break
    case 4:
      str = '已完成'
      break
    case 5:
      str = '审核未通过'
      break
    case 6:
      str = '已取消'
      break
    default:
      str = '--'
  }
  return str
}
/**
 * 订单类型
 * @export
 * @param {*} e
 * @returns
 */
export function orderType (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '新办'
      break
    case 2:
      str = '续次'
      break
    default:
      str = '--'
  }
  return str
}
/**
 * 产品规格
 * @export
 * @param {*} e
 * @returns
 */
export function productSpecs (e) {
  let str = ''
  switch (e) {
    case 0:
      str = '纯API版'
      break
    case 1:
      str = '网页版'
      break
    default:
      str = '--'
  }
  return str
}
/**
 * 产品类型
 * @export
 * @param {*} e
 * @returns
 */
export function productType (e) {
  let str = ''
  switch (e) {
    case 0:
      str = '网络身份认证服务'
      break
    case 1:
      str = '云证书API集成服务( 实名+证书申请 )'
      break
    default:
      str = '--'
  }
  return str
}
/**
 * 云证书API集成服务接口权限
 * @export
 * @param {*} e
 * @returns
 */
export function cloudCertType (e) {
  let str = ''
  switch (e) {
    case '1010':
      str = '个人证书下发'
      break
    case '1011':
      str = '人脸识别认证(二要素验证+人脸匹配)+个人证书申请'
      break
    case '1012':
      str = '运营商三要素信息验证(带手机号验证码)+个人证书申请'
      break
    case '1013':
      str = '银行卡四要素信息验证(带手机号验证码)+个人证书申请'
      break
    case '1014':
      str = '企业证书下发'
      break
    case '1015':
      str = '法定代表人意愿认证(企业认证)+企业证书申请'
      break
    case '1016':
      str = '企业小额对公打款认证（向对方打款随机金额）+企业证书申请'
      break
    case '1017':
      str = '企业小额对公打款认证(对方向贵公司打款)+企业证书申请'
      break
    default:
      str = '--'
  }
  return str
}
/**
 * 企业认证状态
 * @export
 * @param {*} e
 * @returns
 */
export function authStatus (e) {
  let str = ''
  switch (e) {
    case 0:
      str = '认证中'
      break
    case 1:
      str = '已认证'
      break
    case 2:
      str = '认证失败/未认证'
      break
    case 3:
      str = '该企业已被其他账号认证成功，请先解绑企业'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 支付方式
 * @export
 * @param {*} e
 * @returns
 */
export function payWay (e) {
  let str = ''
  switch (e) {
    case 1:
      str = '微信支付'
      break
    case 2:
      str = '银联支付'
      break
    default:
      str = '--'
  }
  return str
}

/**
 * 验签报告业务单类型
 * @export
 * @param {*} e
 * @returns
 */
export function reportStatus (e) {
  let str = ''
  switch (e) {
    case -1:
      str = '已取消'
      break
    case 0:
      str = '出证中'
      break
    case 1:
      str = '待付款'
      break
    case 2:
      str = '待出证'
      break
    case 3:
      str = '出证中'
      break
    case 4:
      str = '出证中'
      break
    case 5:
      str = '待领取'
      break
    case 6:
      str = '已发货'
      break
    case 7:
      str = '已完成'
      break

    default:
      str = '--'
  }
  return str
}