<template>
  <div>
    <Banner title="新闻中心" hint="获取业界最新相关消息，把握数字信息化领域最新风向">
      <img class="banner-bg" style="width: 944px;height: 431px;" :src="bannerBg" alt="新闻中心" srcset="">
    </Banner>
    <div class="tabs-butns">
      <div v-for="(item,index) in newsTypes" :key="index" :class="{active:newsTypeActive===item.val}" @click="newsTypeCg(item.val)">{{item.label}}</div>
    </div>
    <div class="press-list main-box">
      <div class="press" v-for="(item,index) in newsList" :key="index">
        <div class="cover">
          <img :src="item.coverUrl" alt="新闻首图" srcset="">
        </div>
        <div class="rt">
          <div class="title" @click="$router.push({name:'pressDetails',query:{id:item.id}})">{{item.title}}</div>
          <div class="intro">{{item.content|interceptText}}</div>
          <div class="time">{{item.publishTime}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { interceptText } from '@/utils/typeMatch'
export default {
  data () {
    return {
      newsList: '',
      currentPage: 1,
      total: '',
      pages: '',
      pageSize: 10,
      newsTypeActive: 1,
      newsTypes: [
        {
          label: '新闻资讯',
          val: 1
        },
        {
          label: '行业资讯',
          val: 2
        },
        {
          label: '最新动态',
          val: 3
        },
        {
          label: '公司新闻',
          val: 4
        },
        {
          label: '国内热点',
          val: 5
        }
      ],
      bannerBg: require('@/assets/image/aboutZx/pressCenter-banner-bg.png')
    }
  },
  created () {
    this.newsList = []
    this.newsTypeActive = Number(this.$route.query.type) || 1
    this.getNewsList()
  },
  filters: {
    interceptText
  },
  methods: {
    getNewsList () {
      this.$api
        .getNewsList({
          pageIndex: this.currentPage,
          pageSize: this.pageSize,
          type: this.newsTypeActive
        })
        .then(res => {
          this.newsList.push(...res.data.records)
          if (Number(res.data.current) < Number(res.data.pages)) {
            this.currentPage++
            this.getNewsList()
          }
        })
    },
    newsTypeCg (type) {
      this.newsList = []
      this.newsTypeActive = type
      this.currentPage = 1
      this.getNewsList()
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-butns {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(31, 37, 81, 0.11);
  display: flex;
  justify-content: center;
  margin-bottom: 82px;
  & > div {
    line-height: 62px;
    border-bottom: 2px transparent solid;
    font-size: 16px;
    color: #2a2b2c;
    letter-spacing: 1px;
    margin: 0 45px;
    cursor: pointer;
    &.active {
      border-bottom: 2px $primaryColor solid;
    }
  }
}
.press-list {
  margin-bottom: 160px;
  .press {
    display: flex;
    margin-bottom: 90px;

    .cover {
      width: 240px;
      height: 152px;
      position: relative;
      margin-right: 33px;
      flex-shrink: 0;
      img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .rt {
      padding-top: 6px;
      padding-right: 106px;
      .title {
        font-size: 18px;
        color: #303b50;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
        cursor: pointer;
        &:hover {
          color: $primaryColor;
        }
      }
      .intro {
        height: 56px;
        font-size: 14px;
        color: #303b50;
        line-height: 28px;
        margin-bottom: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .time {
        font-size: 12px;
        color: #8d96a3;
        line-height: 16px;
      }
    }
  }
}
</style>
